<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          IMS - POS
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Selamat datang !
          </b-card-title>
          <b-card-text class="mb-2">
            Silahkan masukkan akun aktif anda untuk mulai menggunakan sistem
          </b-card-text>

          <!-- form -->
          <c-form
            class="auth-login-form mt-2"
            @submit="submitForm"
          >
            <b-row>
              <b-col>
                <form-component component-name="b-form-input" :rules="{required: true}" v-model="formData.username" label="Username" autocomplete="username"></form-component>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <form-component component-name="b-form-input" :rules="{required: true}" v-model="formData.password" label="Password" type="password" autocomplete="current-password"></form-component>
              </b-col>
            </b-row>
            <hr>
            <!-- submit buttons -->
            <c-button
              :loading="isloading"
              type="submit"
              block
            >
              Masuk
            </c-button>
          </c-form>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardText, BCardTitle, BImg
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import FormComponent from '@utils/components/FormComponent.vue'
import CForm from '@utils/components/CForm.vue'
import CButton from '@utils/components/CButton.vue'
// yup validation
// import * as yup from 'yup'
// const yupObj = yup.object().shape({
//   username: yup.string().required(),
//   password: yup.string().required()
// })

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    CButton,
    VuexyLogo,
    FormComponent,
    CForm
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      formData:{
        password: '',
        username: '',
      },
      sideImg: require('@/assets/images/pages/coming-soon.svg'),
      isloading: false
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async submitForm() {
      this.isloading = true
      try {
        await this.$store.dispatch('auth/authenticate',this.formData)
        this.$router.push({name:'main'})
        // window.location.href = process.env.BASE_URL
      } catch (data) {
        this.isloading = false
        this.notify(data.data.message,"Login Gagal","danger")
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
