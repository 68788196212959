<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        SMS
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <b-row cols="1">
          <b-col>
            <h2 class="mb-1">
              Stop!
            </h2>
          </b-col>
          <b-col class="mb-2">
            <h5 class="mb-2">
              😖 Hak Akses Anda tidak diperkenankan melihat halaman ini.
            </h5>
            <b-button
              variant="primary"
              class="mr-1"
              @click="goBack"
            >
              Kembali
            </b-button>
            atau
            <b-button @click="logout" variant="primary" class="ml-1" >
              Log Out
            </b-button>
          </b-col>
          <b-col>
            <!-- image -->
            <b-img
              fluid
              :src="imgUrl"
              alt="Error page"
            />
          </b-col>
        </b-row>

      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods:{
    async logout(){
      this.$store.dispatch('auth/unauthenticate');
      this.$router.push({ name: "login" });
    },
    goBack(){
      window.history.back()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
